import React, { useCallback, ReactChild, useState, useRef, useLayoutEffect, ReactNode, RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import Infinite from 'react-infinite';
import styled from 'styled-components';
import { detectOS } from 'detect-browser';
import _ from 'lodash';

import * as UUID from '@/lib/uuid';
import { measureReactElements, useElementDimensions } from '@/lib/layout';
import { useViewport } from '@/lib/layout/dimensions';
import { store } from '@/redux/app/store';
import { useCachedValue, usePersistedValue, usePreviousValue } from '@/lib/react-hooks';
import { inboxInboxModeSelector } from '@/redux/app/selectors/settings';
import { MeasurementsContextProvider, useMeasurements } from '@/lib/layout/measure-react-elements';

const PAGINATION_OFFSET = 500; // in px

class PatchedInfinite extends Infinite {
  constructor(props) {
    super(props);

    if (detectOS(window.navigator.userAgent) !== 'iOS') return;

    // There's an issue with the way scrolling events work on iOS that results in the
    // scrolltop value getting reset during pagination, so we need to patch the
    // implementation and prevent it as much as possible
    const inst = this as any;
    const { recomputeInternalStateFromProps } = inst;
    inst.recomputeInternalStateFromProps = (nextProps) => {
      const state = recomputeInternalStateFromProps(nextProps);

      if (!nextProps.useWindowAsScrollContainer) {
        state.utils.getScrollTop = () => {
          if (!inst.scrollable) return 0;
          let scrolltop = inst.scrollable.scrollTop;
          if (inst.inertScrollTop !== undefined
            && Math.abs(inst.inertScrollTop - scrolltop) > 1000
          ) {
            scrolltop = inst.inertScrollTop;
            inst.scrollable.scrollTop = scrolltop;
          } else {
            inst.inertScrollTop = scrolltop;
          }
          return scrolltop;
        };
        state.utils.setScrollTop = (value) => {
          if (inst.scrollable) {
            inst.scrollable.scrollTop = value;
            inst.inertScrollTop = value;
          }
        };
      }

      return state;
    };
  }

  // Change for React 18: https://reactjs.org/link/unsafe-component-lifecycles
  componentWillReceiveProps = null;

  UNSAFE_componentWillReceiveProps = Infinite.prototype.componentWillReceiveProps;

  componentWillUpdate = null;

  UNSAFE_componentWillUpdate = Infinite.prototype.componentWillUpdate;
}

interface Props {
  isNextPageLoading: boolean;
  loadNextPage: () => void;
  height?: number;
  loader?: Infinite.InfiniteProps['loadingSpinnerDelegate'];
}

const ScrollLatestContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const ScrollLatestInner = styled.div`
  background: rgba(0,0,0,0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 500px;
  margin: 10px 0;
  display: inline-block;
  cursor: pointer;
`;
export interface BasicProps extends Props {
  children: JSX.Element[];
  containerRef: React.MutableRefObject<HTMLDivElement>;
  rowHeight: number | number[];
}
export interface DynamicProps extends Props {
  list: string[];
  // Needed to correctly update list items that can re-render and change their heights (e.g. Redux)
  renderIds?: string[];
  renderItem: (item: this['list'][number], index: number) => ReactChild;
  assumeSameRowHeight?: boolean;
  // If the scroll distance to the bottom is less than this value in px,
  // appending new items to the bottom resets scroll position
  stickyAppendMargin?: number;
  overflow: 'visible' | 'hidden' | 'scroll' | 'auto' | 'clip' | 'inherit' | 'initial' | 'revert' | 'unset';
}

export const Basic = React.forwardRef(({
  children, loader, isNextPageLoading, loadNextPage, height, rowHeight, containerRef,
}: BasicProps, ref: React.RefObject<PatchedInfinite>) => {
  const [scrollLatest, setScrollLatest] = useState(false);
  const loadMoreRows = useCallback(() => {
    if (!isNextPageLoading) loadNextPage();
  }, [isNextPageLoading, loadNextPage]);

  const handlePressGoToBottom = () => {
    const inst: any = ref.current;
    const scrollDiff = inst.getLowestPossibleScrollTop();
    inst.handleScroll(scrollDiff, 'handleScrollBottom');
    setScrollLatest(false);
  };

  const handleScroll = useCallback(_.throttle(() => {
    const inst: any = ref.current;
    if (!inst) return;

    const scrollDiff = inst.getLowestPossibleScrollTop();
    const scrollTop = inst.utils.getScrollTop();
    const scrollPosition = scrollDiff - scrollTop;
    // We want this to be less than 600 on mobile
    if (scrollPosition > Math.min(window.innerHeight / 2, 600)) {
      setScrollLatest(true);
    } else {
      setScrollLatest(false);
    }
  }, 500), []);

  let content: JSX.Element;
  if (children) {
    content = typeof height === 'number'
      ? (
        <PatchedInfinite
          ref={ref}
          displayBottomUpwards
          containerHeight={height}
          elementHeight={rowHeight}
          infiniteLoadBeginEdgeOffset={PAGINATION_OFFSET}
          onInfiniteLoad={loadMoreRows}
          handleScroll={handleScroll}
          loadingSpinnerDelegate={loader}
          isInfiniteLoading={isNextPageLoading}
        >
          {children}
        </PatchedInfinite>
      )
      : (
        <AutoSizer disableWidth>
          {({ height: calculatedHeight }) => (
            !calculatedHeight ? null : (
              <PatchedInfinite
                ref={ref}
                displayBottomUpwards
                containerHeight={calculatedHeight || -1}
                elementHeight={rowHeight}
                infiniteLoadBeginEdgeOffset={PAGINATION_OFFSET}
                onInfiniteLoad={loadMoreRows}
                handleScroll={handleScroll}
                loadingSpinnerDelegate={loader}
                isInfiniteLoading={isNextPageLoading}
              >
                {children}
              </PatchedInfinite>
            )
          )}
        </AutoSizer>
      );
  }
  return (
    <div id="basic-inner-container" ref={containerRef} style={{ height: '100%' }}>
      {content || null}
      {scrollLatest && (
        <ScrollLatestContainer onClick={handlePressGoToBottom}>
          <ScrollLatestInner>
            Scroll to Bottom
          </ScrollLatestInner>
        </ScrollLatestContainer>
      )}
    </div>
  );
});

export const HeightMeasurer = (props: { id: string, children: (ref: RefObject<any>) => ReactNode }) => {
  const ref = useRef<HTMLElement>();
  const { setMeasurement } = useMeasurements();
  useEffect(() => {
    const height = ref.current.children[0]?.getBoundingClientRect().height ?? null;
    setMeasurement(props.id, { height });
  }, [props.id]);
  return props.children(ref);
};

export const Dynamic = ({
  list, renderIds, renderItem, assumeSameRowHeight = true, loadNextPage, isNextPageLoading,
  stickyAppendMargin, overflow = 'hidden',
  ...rest
}: DynamicProps) => {
  const [eventsContainerWidth, setEventsContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const [computedRowHeight, setComputedRowHeight] = useState<number | number[]>();
  const savedMeasurementsWrapper = usePersistedValue({});
  const savedMeasurements = savedMeasurementsWrapper.get();
  const measurementId = usePersistedValue<string>(null);
  const measurementPromise = usePersistedValue(Promise.resolve());
  const inboxMode = useSelector(inboxInboxModeSelector);

  const isWide = inboxMode === 'wide';
  const isMobile = useViewport((width) => width < 600, 500);

  useLayoutEffect(() => {
    if (list.length < 1) setComputedRowHeight(undefined);
    else {
      const newMeasurements = { ...savedMeasurements };
      const elementsToMeasure = [];
      const styleHash: Partial<Record<'padding' | 'width', string>> = { padding: '0 15px' };
      if (isWide) {
        styleHash.width = '650px';
      }

      (assumeSameRowHeight ? list.slice(0, 1) : list).forEach((item, ix) => {
        const renderId = renderIds ? renderIds[ix] : item;
        const measuredElementId = `height-measurer-${renderId}`;
        const savedValue = newMeasurements[measuredElementId];

        if (!savedValue) {
          const element = (
            <HeightMeasurer id={measuredElementId}>
              {(ref) => (
                <div
                  ref={ref}
                  className="ticket-view-container"
                  style={styleHash}
                >
                  {renderItem(item, ix)}
                </div>
              )}
            </HeightMeasurer>
          );
          elementsToMeasure.push(element);
        }
      });

      if (elementsToMeasure.length > 0) {
        const currentMeasurementId = UUID.generate();
        measurementId.set(currentMeasurementId);

        // properly chain measurements by persisting promise across re-renders
        const prevPromise = measurementPromise.get();
        measurementPromise.set((async () => {
          await prevPromise;
          const measurements =
            await measureReactElements(containerRef.current, elementsToMeasure, store);
          // naive comparison
          if (JSON.stringify(newMeasurements) !== JSON.stringify(measurements)) {
            Object.assign(newMeasurements, measurements);
          }
          savedMeasurementsWrapper.set(newMeasurements);

          if (currentMeasurementId === measurementId.get()) {
            // next measurement hasnt been scheduled yet
            const rowHeights = renderIds.map((renderId) => newMeasurements[`height-measurer-${renderId}`]?.height ?? 0);
            measurementId.set(null);
            setComputedRowHeight(rowHeights);
          }
        })());
      }
    }
    return () => { measurementId.set(null); };
  }, [list, renderItem, renderIds, eventsContainerWidth, assumeSameRowHeight, containerRef]);

  useElementDimensions('basic-inner-container', (width) => {
    if (width === eventsContainerWidth) return;
    savedMeasurementsWrapper.set({});
    setEventsContainerWidth(width);
  }, 200);

  const prevComputedRowHeight = usePreviousValue(computedRowHeight);
  const prevOverflow = usePreviousValue(overflow);

  const cachedContent = useCachedValue(() => list.map(
    (item, index) => (
      <div
        key={item}
        style={{
          height: computedRowHeight && (
            computedRowHeight instanceof Array ? computedRowHeight[index] : computedRowHeight
          ),
          overflowY: overflow,
        }}
        className="reverse-infinite-list-item"
      >
        {renderItem(item, index)}
      </div>
    ),
  ), computedRowHeight !== prevComputedRowHeight || overflow !== prevOverflow);
  const cachedIsNextPageLoading = useCachedValue(
    () => isNextPageLoading,
    isNextPageLoading || computedRowHeight !== prevComputedRowHeight,
  );

  const loadNextPageIfReady = useCallback(() => {
    if (measurementId.get()) return;
    loadNextPage();
  }, [loadNextPage]);

  const lastItem = _.last(list);
  const listRef = useRef<PatchedInfinite>();
  useLayoutEffect(() => { // Scroll to the bottom when a new item is appended
    if (!lastItem || typeof stickyAppendMargin !== 'number') return;
    const inst: any = listRef.current;
    if (!inst) return;
    const scrollDiff = inst.getLowestPossibleScrollTop();
    const scrollPosition = scrollDiff - inst.utils.getScrollTop();
    if (scrollPosition < stickyAppendMargin) inst.handleScroll(scrollDiff, 'layouteffect');
  }, [lastItem]);

  return (
    <MeasurementsContextProvider measurements={savedMeasurements}>
      <Basic
        ref={listRef}
        containerRef={containerRef}
        rowHeight={computedRowHeight || -1}
        loadNextPage={loadNextPageIfReady}
        isNextPageLoading={cachedIsNextPageLoading}
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
      >
        {cachedContent}
      </Basic>
    </MeasurementsContextProvider>
  );
};
