import type { Store } from 'redux';
import React, { useLayoutEffect, createElement, ReactElement } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import invariant from 'invariant';

import '@/types/dom';

const getFiberNode = (domNode: Element) => {
  // https://stackoverflow.com/a/39165137/8207842
  const key = Object.keys(domNode).find((p) => p.startsWith('__reactFiber$'));
  return domNode[key];
};

const collectContexts = (fiberNode: any) => {
  let node = fiberNode;
  const contexts: Array<[React.Context<any>, any]> = [];
  while (node.return) {
    node = node.return;
    if (node.type?.$$typeof === Symbol.for('react.provider')) {
      const { value } = node.memoizedProps;
      const Context = node.type._context; // eslint-disable-line no-underscore-dangle
      contexts.push([Context, value]);
    }
  }
  return contexts;
};

interface MeasurementsContainerProps {
  children: React.ReactChild[];
  onMount: () => void;
  reduxStore?: Store;
  contexts: Array<[React.Context<any>, any]>;
}

export const MeasurementsContainerContext = React.createContext(false);

const MeasurementsContainer = (
  { children, onMount, reduxStore, contexts }: MeasurementsContainerProps,
) => {
  useLayoutEffect(onMount, []);
  let element: ReactElement = createElement('div', {}, ...children);
  if (reduxStore) element = createElement(Provider, { store: reduxStore }, element);
  element = createElement(MeasurementsContainerContext.Provider, { value: true }, element);
  element = contexts.reduce(
    (acc, [Context, value]) => createElement(Context.Provider, { value }, acc),
    element,
  );
  return element;
};

export interface MeasurementContextValue {
  setMeasurement: <T extends Record<string, any>>(uniqueId: string, value: T) => void;
  getMeasurement: <T extends Record<string, any>>(uniqueId: string) => T | undefined;
}
const MeasurementContext = React.createContext<MeasurementContextValue>({ setMeasurement: () => {}, getMeasurement: () => undefined });
export const useMeasurements = () => React.useContext(MeasurementContext);

export type MeasurementsContextProviderProps = React.PropsWithChildren<{
  measurements: { [uniqueId: string]: Record<string, any> };
}>;
export const MeasurementsContextProvider = ({ measurements, children }: MeasurementsContextProviderProps) => React.createElement(
  MeasurementContext.Provider,
  {
    value: {
      setMeasurement: () => {},
      getMeasurement: (uniqueId) => measurements[uniqueId] as any,
    },
    children,
  },
);

export const measureReactElements =
  async (domNode: HTMLElement, elements: React.ReactChild[], reduxStore?: Store) => {
    if (elements.length < 1) return [];

    const container = domNode.closest('.measurements-container');
    invariant(!!container, 'Could not find measurements container');

    const canvas = container.querySelector('.measurements-canvas');
    invariant(!!canvas, 'Could not find measurements canvas');

    const fiberNode = getFiberNode(canvas);
    const contexts = collectContexts(fiberNode);

    let resolvePromise: () => void;
    const promise = new Promise((resolve: any) => {
      resolvePromise = resolve;
    });

    const rootElement = React.createElement(
      MeasurementsContainer,
      { onMount: resolvePromise, reduxStore, contexts, children: elements },
    );

    const measurements = {} as MeasurementsContextProviderProps['measurements'];
    const wrappedRootElement = React.createElement(MeasurementContext.Provider, {
      value: {
        setMeasurement: (uniqueId, measurement) => { measurements[uniqueId] = measurement; },
        getMeasurement: (uniqueId) => measurements[uniqueId] as any,
      },
      children: rootElement,
    });

    const reactRoot = createRoot(canvas);
    reactRoot.render(wrappedRootElement); // eslint-disable-line
    await promise;
    reactRoot.unmount();

    return measurements;
  };
