import styled from 'styled-components';

import { Without } from '@/types/generic';
import { colors } from './MainTheme';
import { Span } from './Span';

export type SpanProps = {
  onClick?: any;
} & Without<JSX.IntrinsicElements['span'], 'ref'>;

export const SpanStyle = styled(Span)<SpanProps>`
  cursor: pointer;
  color: ${colors.linkBlue};
`;

export const SpanLink = ({
  children, onClick, ...props
}: SpanProps) => (
  <SpanStyle
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={onClick}
    {...props}
  >
    {children}
  </SpanStyle>
);
